import { useContext } from 'react';

import { SlotsContentContext } from '../../components/slots-state/SlotsContentContext';
import { SlotID } from '../../slot-declarations/basicSlotIDs';

export const useSlot = (slotId: SlotID) => {
  const slots = useContext(SlotsContentContext);

  return slots[slotId];
};
