export function isShallowEqual<T extends object>(
  newObject: T | undefined,
  prevObject: T | undefined,
): boolean {
  if (prevObject === newObject) {
    return true;
  }

  if (prevObject === undefined || newObject === undefined) {
    return false;
  }

  for (const prop in newObject) {
    if (!newObject.hasOwnProperty(prop)) {
      continue;
    }

    if (newObject[prop] !== prevObject[prop]) {
      return false;
    }
  }

  if (Object.keys(prevObject).length !== Object.keys(newObject).length) {
    return false;
  }

  return true;
}
