export * from './slot-declarations';

export { SlotsStateProvider } from './components/slots-state/SlotsStateProvider';

export { EditorOpenedPanelsProvider } from './components/editor-integration/opened-panels/EditorOpenedPanelsProvider';
export { ExtensionDataProvider } from './components/extension/ExtensionDataProvider';

export { useEditorSDK } from './hooks/environment/useEditorSDK';
export { useDocumentServices } from './hooks/environment/useDocumentServices';
export { useServiceTopology } from './hooks/environment/useServiceTopology';
export { useTranslation } from './hooks/environment/useTranslation';
export { useBiLogger } from './hooks/environment/useBiLogger';
export { useExtensionId } from './hooks/environment/useExtensionId';
export { useBoundedPanelAPI } from './hooks/panels/useBoundedPanelAPI';
export { useEditorExperiments } from './hooks/environment/useEditorExperiments';
export { useExperiments } from './hooks/environment/useExperiments';

export { SlotsState } from './types/slotsState';
export { OpenedPanel } from './types/openedPanels';
export { PanelAPI, PanelType } from './types/panelAPI';

export {
  extensionsSlotsReducer,
  extensionsSlotsInitialState,
} from './state-management/extensionsSlotsReducer';
export {
  registerSlotPortal,
  unregisterSlotPortal,
  updateSlotPortalProps,
  registerSlotPortalNode,
  unregisterSlotPortalNode,
} from './state-management/extensionsSlotsActions';
export { getSlots } from './state-management/extensionsSlotsSelectors';
