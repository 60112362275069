import { declareSlot } from '../../utils/slotsUtils';
import { SlotID } from '../basicSlotIDs';

type TopBarSecondaryActionsSlotProps = {
  symbolName: string;
  isActive: boolean;
  onClick: () => void;
  automationId?: string;
  tooltip: {
    title?: string;
    text: string;
  };
};

export const TopBarSecondaryActionsSlot = declareSlot<TopBarSecondaryActionsSlotProps>(
  SlotID.TopBarSecondaryActions,
);
