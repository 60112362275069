import React, { PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { SlotID } from '../slot-declarations/basicSlotIDs';
import { usePortalNodes } from '../hooks/slots/usePortalNodes';
import { useSlotsAPI } from '../hooks/slots/useSlotsAPI';
import { useExtensionId } from '../hooks/environment/useExtensionId';
import { useSlotPortalPropsUpdate } from '../hooks/slots/useSlotPortalPropsUpdate';
import { useUniqueId } from '../hooks/utils/useUniqueId';

export const SlotPortal = <TSlotProps extends Record<string, any> = {}>(
  props: PropsWithChildren<
    {
      slotId: SlotID;
    } & TSlotProps
  >,
) => {
  const { registerSlotPortal, unregisterSlotPortal } = useSlotsAPI();
  const extensionId = useExtensionId();

  const slotPortalId = useUniqueId();

  const { slotId, children, ...slotOwnProps } = props;

  useEffect(() => {
    registerSlotPortal(slotId, extensionId, slotPortalId);

    return () => {
      unregisterSlotPortal(slotId, extensionId, slotPortalId);
    };
  }, [
    slotId,
    extensionId,
    slotPortalId,
    registerSlotPortal,
    unregisterSlotPortal,
  ]);

  useSlotPortalPropsUpdate(slotId, extensionId, slotPortalId, slotOwnProps);

  const portalNodes = usePortalNodes(slotPortalId);

  if (portalNodes.length === 0 || !children) {
    return null;
  }

  return (
    <>
      {portalNodes.map((portalNode) =>
        ReactDOM.createPortal(children, portalNode.node, portalNode.nodeId),
      )}
    </>
  );
};
