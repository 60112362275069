import { declareSlot } from '../../utils/slotsUtils';
import { SlotID } from '../basicSlotIDs';

type PrimaryPanelsSlotProps = {
  // required for panel opening animation in Classic Editor
  width: string;
  label?: string;
  panelClass?: string;
  automationId?: string;
};

export const PrimaryPanelsSlot = declareSlot<PrimaryPanelsSlotProps>(
  SlotID.PrimaryPanels,
);
