import {
  REGISTER_SLOT_PORTAL,
  UNREGISTER_SLOT_PORTAL,
  REGISTER_SLOT_PORTAL_NODE,
  UNREGISTER_SLOT_PORTAL_NODE,
  UPDATE_SLOT_PORTAL_PROPS,
} from './extensionsSlotsActionsTypes';
import type { ExtensionsSlotsActions } from './extensionsSlotsActions';
import { SlotsState } from '../types/slotsState';

export const extensionsSlotsInitialState: SlotsState = {
  portals: {},
  portalNodes: {},
};

export const extensionsSlotsReducer = (
  state = extensionsSlotsInitialState,
  action: ExtensionsSlotsActions,
): SlotsState => {
  switch (action.type) {
    case REGISTER_SLOT_PORTAL: {
      const { slotId, extensionId, portalId } = action.payload;

      return {
        ...state,
        portals: {
          ...state.portals,
          [slotId]: {
            ...state.portals[slotId],
            [extensionId]: [
              ...(state.portals[slotId]?.[extensionId] ?? []),
              {
                portalId,
                extensionId,
                ownProps: {},
              },
            ],
          },
        },
      };
    }

    case UNREGISTER_SLOT_PORTAL: {
      const { slotId, extensionId, portalId } = action.payload;

      return {
        ...state,
        portals: {
          ...state.portals,
          [slotId]: {
            ...state.portals[slotId],
            [extensionId]: state.portals[slotId][extensionId].filter(
              (slotPortal) => slotPortal.portalId !== portalId,
            ),
          },
        },
      };
    }

    case UPDATE_SLOT_PORTAL_PROPS: {
      const { slotId, extensionId, portalId, portalProps } = action.payload;

      return {
        ...state,
        portals: {
          ...state.portals,
          [slotId]: {
            ...state.portals[slotId],
            [extensionId]: (state.portals[slotId]?.[extensionId] ?? []).map(
              (slotPortal) => {
                if (slotPortal.portalId === portalId) {
                  return {
                    ...slotPortal,
                    ownProps: portalProps,
                  };
                }

                return slotPortal;
              },
            ),
          },
        },
      };
    }

    case REGISTER_SLOT_PORTAL_NODE: {
      const { portalId, portalNodeId, placementId } = action.payload;

      return {
        ...state,
        portalNodes: {
          ...state.portalNodes,
          [portalId]: [
            ...(state.portalNodes[portalId] ?? []),
            {
              portalNodeId,
              placementId,
            },
          ],
        },
      };
    }

    case UNREGISTER_SLOT_PORTAL_NODE: {
      const { portalId, placementId } = action.payload;

      return {
        ...state,
        portalNodes: {
          ...state.portalNodes,
          [portalId]: state.portalNodes[portalId].filter(
            (portalNode) => portalNode.placementId !== placementId,
          ),
        },
      };
    }

    default: {
      return state;
    }
  }
};
