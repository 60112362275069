import { useMemo } from 'react';

import { SlotID } from '../../slot-declarations/basicSlotIDs';
import { useSlot } from './useSlot';
import { SlotPortal } from '../../types/slotsState';

export function useSlotPortals(
  slotId: SlotID,
  filter: (extensionsIds: string[]) => string[],
  sort: (extensionsIds: string[]) => string[],
): SlotPortal[] {
  const slot = useSlot(slotId);

  return useMemo(() => {
    if (!slot || Object.keys(slot).length === 0) {
      return [];
    }

    const extensionsIds = Object.keys(slot);

    const filteredExtensionIds = filter(extensionsIds);

    const sortedExtensionsIds = sort(filteredExtensionIds);

    return sortedExtensionsIds.flatMap((extensionId) => slot[extensionId]);
  }, [slot, filter, sort]);
}
