import React, { FunctionComponent } from 'react';
import { OpenedPanel } from '../../../types/openedPanels';
import { EditorOpenedPanelsContext } from './EditorOpenedPanelsContext';

export const EditorOpenedPanelsProvider: FunctionComponent<{
  openedPanels: OpenedPanel[];
}> = (props) => {
  return (
    <EditorOpenedPanelsContext.Provider value={props.openedPanels}>
      {props.children}
    </EditorOpenedPanelsContext.Provider>
  );
};
