import React, { useMemo, ReactElement, useLayoutEffect } from 'react';

import { SlotID } from '../slot-declarations/basicSlotIDs';
import { useSlotsAPI } from '../hooks/slots/useSlotsAPI';
import { useSlotPortals } from '../hooks/slots/useSlotPortals';
import { SlotPortal } from '../types/slotsState';
import { SlotPlacementIntegrationProps } from '../types/slotPlacement';
import { typedMemo } from '../utils/typedMemo';
import { useUniqueId } from '../hooks/utils/useUniqueId';

const SlotPortalPlacement = typedMemo(
  <TSlotProps extends Record<string, any> = {}>(props: {
    slotId: SlotID;
    slotPortal: SlotPortal;
    render: (
      integrationProps: SlotPlacementIntegrationProps,
      ownProps: TSlotProps,
    ) => ReactElement;
  }) => {
    const { portalId, ownProps = {} } = props.slotPortal;

    const { registerSlotPortalNode, unregisterSlotPortalNode } = useSlotsAPI();
    const slotPlacementId = useUniqueId();

    const portalNodeId = `${props.slotId}.${props.slotPortal.extensionId}.${portalId}.${slotPlacementId}`;
    const integrationProps = useMemo<SlotPlacementIntegrationProps>(
      () => ({
        'data-extensions-portal-node-id': portalNodeId,
      }),
      [portalNodeId],
    );

    useLayoutEffect(() => {
      registerSlotPortalNode(portalId, slotPlacementId, portalNodeId);

      return () => {
        unregisterSlotPortalNode(portalId, slotPlacementId);
      };
    }, [
      registerSlotPortalNode,
      unregisterSlotPortalNode,
      portalId,
      portalNodeId,
      slotPlacementId,
    ]);

    return props.render(integrationProps, ownProps as TSlotProps);
  },
);

const SlotPortalPlacementList = typedMemo(
  <TSlotProps extends Record<string, any> = {}>(props: {
    slotId: SlotID;
    slotPortals: SlotPortal[];
    render: (
      integrationProps: SlotPlacementIntegrationProps,
      ownProps: TSlotProps,
    ) => ReactElement;
  }) => (
    <>
      {props.slotPortals.map((slotPortal) => (
        <SlotPortalPlacement<TSlotProps>
          slotId={props.slotId}
          slotPortal={slotPortal}
          key={slotPortal.portalId}
          render={props.render}
        />
      ))}
    </>
  ),
);

export const SlotPlacement = <
  TSlotProps extends Record<string, any> = {}
>(props: {
  slotId: SlotID;
  filter: (extensionsIds: string[]) => string[];
  sort: (extensionsIds: string[]) => string[];
  render: (
    integrationProps: SlotPlacementIntegrationProps,
    ownProps: TSlotProps,
  ) => ReactElement;
}) => {
  const { slotId, render, filter, sort } = props;

  const slotPortals = useSlotPortals(slotId, filter, sort);

  return useMemo(() => {
    if (slotPortals.length === 0) {
      return null;
    }

    return (
      <SlotPortalPlacementList<TSlotProps>
        slotId={slotId}
        slotPortals={slotPortals}
        render={render}
      />
    );
  }, [slotPortals, slotId, render]);
};
