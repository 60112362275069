import { useCallback, useMemo } from 'react';
import { useEditorPanelAPI } from './useEditorPanelAPI';
import type { PanelAPI, PanelType } from '../../types/panelAPI';
import { useUniqueId } from '../utils/useUniqueId';

export const useBoundedPanelAPI = (panelType: PanelType) => {
  const { openPanel, closePanel, isPanelOpened } = useEditorPanelAPI(panelType);

  const panelName = useUniqueId();

  const boundedOpenPanel = useCallback(
    (options?: { leavePanelsOpened: boolean }) => openPanel(panelName, options),
    [openPanel, panelName],
  );
  const boundedClosePanel = useCallback(() => closePanel(panelName), [
    closePanel,
    panelName,
  ]);
  const boundedIsPanelOpened = useCallback(() => isPanelOpened(panelName), [
    isPanelOpened,
    panelName,
  ]);

  return useMemo<PanelAPI>(
    () => ({
      openPanel: boundedOpenPanel,
      closePanel: boundedClosePanel,
      isPanelOpened: boundedIsPanelOpened,
    }),
    [boundedOpenPanel, boundedClosePanel, boundedIsPanelOpened],
  );
};
