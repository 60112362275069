import { useCallback, useMemo } from 'react';
import { useExtensionId } from '../environment/useExtensionId';
import { useEditorSDK } from '../environment/useEditorSDK';
import { useOpenedPanels } from './useOpenedPanels';

import { EditorPanelAPI, PanelType } from '../../types/panelAPI';

function buildPanelToken(extensionId: string, panelName: string): string {
  return `${extensionId}_${panelName}`;
}

export const useEditorPanelAPI = (panelType: PanelType): EditorPanelAPI => {
  const editorSDK = useEditorSDK();
  const extensionId = useExtensionId();
  const openedPanels = useOpenedPanels();

  const openPanel = useCallback(
    (
      panelName: string,
      options: { leavePanelsOpened: boolean } = { leavePanelsOpened: false },
    ) => {
      return editorSDK.editor.openPanel(
        panelType,
        {
          token: buildPanelToken(extensionId, panelName),
        },
        options.leavePanelsOpened,
      );
    },
    [editorSDK, extensionId, panelType],
  );

  const closePanel = useCallback(
    (panelName: string) => {
      const token = buildPanelToken(extensionId, panelName);
      return editorSDK.editor.closePanel(token, undefined);
    },
    [editorSDK, extensionId],
  );

  const isPanelOpened = useCallback(
    (panelName: string) => {
      const token = buildPanelToken(extensionId, panelName);

      return openedPanels.some((panel) => panel.token === token);
    },
    [openedPanels, extensionId],
  );

  return useMemo(
    () => ({
      openPanel,
      closePanel,
      isPanelOpened,
    }),
    [openPanel, closePanel, isPanelOpened],
  );
};
