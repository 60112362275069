import React, { FunctionComponent } from 'react';

import { SlotsAPIContext } from './SlotsAPIContext';
import { SlotsContentContext } from './SlotsContentContext';
import { SlotsState } from '../../types/slotsState';
import { SlotsStateAPI } from '../../types/slotsStateAPI';
import { SlotsPortalNodesContext } from './SlotsPortalNodesContext';

export const SlotsStateProvider: FunctionComponent<{
  slots: SlotsState;
  slotsAPI: SlotsStateAPI;
}> = (props) => (
  <SlotsContentContext.Provider value={props.slots.portals}>
    <SlotsPortalNodesContext.Provider value={props.slots.portalNodes}>
      <SlotsAPIContext.Provider value={props.slotsAPI}>
        {props.children}
      </SlotsAPIContext.Provider>
    </SlotsPortalNodesContext.Provider>
  </SlotsContentContext.Provider>
);
