import { declareSlot } from '../../utils/slotsUtils';
import { SlotID } from '../basicSlotIDs';

type StickyPanelSlotProps = {
  title?: string;
  automationId?: string;
};

export const StickyPanelSlot = declareSlot<StickyPanelSlotProps>(
  SlotID.StickyPanel,
);
