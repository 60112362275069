import { useContext } from 'react';

import { SlotsPortalNodesContext } from '../../components/slots-state/SlotsPortalNodesContext';

type PortalNode = {
  node: Element;
  nodeId: string;
};

export const usePortalNodes = (slotPortalId: string): PortalNode[] => {
  const portalNodes = useContext(SlotsPortalNodesContext);

  const slotPortalNodes = portalNodes[slotPortalId] || [];

  const portalDomNodeSelectors = slotPortalNodes.map(
    (portalNode) =>
      `[data-extensions-portal-node-id="${portalNode.portalNodeId}"]`,
  );

  return portalDomNodeSelectors
    .map((selector) => ({
      node: document.querySelector(selector),
      nodeId: selector,
    }))
    .filter((portalNode): portalNode is PortalNode => portalNode.node !== null);
};
