import React, { FC, memo, PropsWithChildren } from 'react';

import { ExtensionDataContext } from './ExtensionDataContext';
import { ExtensionData } from '../../types/extensionData';
import { isShallowEqual } from '../../utils/isShallowEqual';

const shallowCompareAllButChildren = (
  prevProps: PropsWithChildren<ExtensionData>,
  nextProps: PropsWithChildren<ExtensionData>,
) => {
  const { children: prevChildren, ...prevPropsWithNoChildren } = prevProps;
  const { children: nextChildren, ...nextPropsWithNoChildren } = nextProps;

  return isShallowEqual(prevPropsWithNoChildren, nextPropsWithNoChildren);
};

export const ExtensionDataProvider: FC<ExtensionData> = memo((props) => {
  return (
    <ExtensionDataContext.Provider value={props}>
      {props.children}
    </ExtensionDataContext.Provider>
  );
}, shallowCompareAllButChildren);
