import {
  REGISTER_SLOT_PORTAL,
  REGISTER_SLOT_PORTAL_NODE,
  UPDATE_SLOT_PORTAL_PROPS,
  UNREGISTER_SLOT_PORTAL,
  UNREGISTER_SLOT_PORTAL_NODE,
} from './extensionsSlotsActionsTypes';

export const registerSlotPortal = (
  slotId: string,
  extensionId: string,
  portalId: string,
) => ({
  type: REGISTER_SLOT_PORTAL,
  payload: {
    slotId,
    extensionId,
    portalId,
  },
});

export const unregisterSlotPortal = (
  slotId: string,
  extensionId: string,
  portalId: string,
) => ({
  type: UNREGISTER_SLOT_PORTAL,
  payload: {
    slotId,
    extensionId,
    portalId,
  },
});

export const updateSlotPortalProps = <T extends Record<string, any>>(
  slotId: string,
  extensionId: string,
  portalId: string,
  portalProps: T,
) => ({
  type: UPDATE_SLOT_PORTAL_PROPS,
  payload: {
    slotId,
    extensionId,
    portalId,
    portalProps,
  },
});

export const registerSlotPortalNode = (
  portalId: string,
  placementId: string,
  portalNodeId: string,
) => ({
  type: REGISTER_SLOT_PORTAL_NODE,
  payload: {
    portalId,
    portalNodeId,
    placementId,
  },
});

export const unregisterSlotPortalNode = (
  portalId: string,
  placementId: string,
) => ({
  type: UNREGISTER_SLOT_PORTAL_NODE,
  payload: {
    portalId,
    placementId,
  },
});

type RegisterSlotPortalAction = ReturnType<typeof registerSlotPortal>;
type UnregisterSlotPortalAction = ReturnType<typeof unregisterSlotPortal>;
type UpdateSlotPortalPropsAction = ReturnType<typeof updateSlotPortalProps>;
type RegisterSlotPortalNodeAction = ReturnType<typeof registerSlotPortalNode>;
type UnregisterSlotPortalNodeAction = ReturnType<
  typeof unregisterSlotPortalNode
>;

export type ExtensionsSlotsActions =
  | RegisterSlotPortalAction
  | UnregisterSlotPortalAction
  | UpdateSlotPortalPropsAction
  | RegisterSlotPortalNodeAction
  | UnregisterSlotPortalNodeAction;
