import { useEffect, useRef } from 'react';

export const usePreviousValue = <T>(value: T): T | undefined => {
  const prevValueRef = useRef<T>();

  useEffect(() => {
    prevValueRef.current = value;
  });

  return prevValueRef.current;
};
