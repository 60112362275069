import { declareSlot } from '../../utils/slotsUtils';
import { SlotID } from '../basicSlotIDs';

type HelpMenuItemsSlotProps = {
  // menu item props
  leftTitle: string;
  onClick: () => void;
  isDisabled?: boolean;

  // image props
  symbolName?: string;
  image?: string;
  rightTitle: string;
  description: string;
  illustrationClassName?: string;
};

export const HelpMenuItemsSlot = declareSlot<HelpMenuItemsSlotProps>(
  SlotID.HelpMenuItems,
);
