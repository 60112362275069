import { declareSlot } from '../../utils/slotsUtils';
import { SlotID } from '../basicSlotIDs';

type PrimaryButtonsSlotProps = {
  label: string;
  symbolName: string;
  className: string;
  isSelected: boolean;
  onClick: () => void;
  automationId?: string;

  // todo: remove these old left bar props when the old left bar is deleted
  collapseOnClick?: boolean;
  isButtonOpen?: boolean;
  hintProps?: any;
  tooltips?: any[];
};

export const PrimaryButtonsSlot = declareSlot<PrimaryButtonsSlotProps>(
  SlotID.PrimaryButtons,
);
