import { memo, PropsWithChildren, ReactElement } from 'react';

import { SlotPlacement } from '../components/SlotPlacement';
import { SlotPortal } from '../components/SlotPortal';
import { SlotID } from '../slot-declarations/basicSlotIDs';
import { SlotPlacementIntegrationProps } from '../types/slotPlacement';

const identity = <T>(data: T): T => data;

function createSlotPlacement<TSlotProps extends Record<string, any> = {}>(
  slotId: SlotID,
) {
  const Placement = (props: {
    filter?: (extensionsIds: string[]) => string[];
    sort?: (extensionsIds: string[]) => string[];
    children: (
      integrationProps: SlotPlacementIntegrationProps,
      ownProps: TSlotProps,
    ) => ReactElement;
  }) =>
    SlotPlacement<TSlotProps>({
      slotId,
      filter: props.filter || identity,
      sort: props.sort || identity,
      render: props.children,
    });

  Placement.displayName = `${slotId}.Placement`;

  return memo(Placement);
}

function createSlotPortal<TSlotProps extends Record<string, any> = {}>(
  slotId: SlotID,
) {
  const Portal = (props: PropsWithChildren<TSlotProps>) =>
    SlotPortal<TSlotProps>({
      ...props,
      slotId,
    });

  Portal.displayName = `${slotId}.Portal`;

  return memo(Portal);
}

export function declareSlot<TSlotProps extends Record<string, any> = {}>(
  slotId: SlotID,
) {
  return {
    Placement: createSlotPlacement<TSlotProps>(slotId),
    Portal: createSlotPortal<TSlotProps>(slotId),
  };
}
