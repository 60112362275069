import { useSlotsAPI } from './useSlotsAPI';
import { useEffect } from 'react';
import { usePreviousValue } from '../utils/usePreviousValue';
import { isShallowEqual } from '../../utils/isShallowEqual';

export const useSlotPortalPropsUpdate = <
  TSlotProps extends Record<string, any>
>(
  slotId: string,
  extensionId: string,
  portalId: string,
  portalProps: TSlotProps,
) => {
  const { updateSlotPortalProps } = useSlotsAPI();
  const prevProps = usePreviousValue(portalProps);

  useEffect(() => {
    const propsChanged = !isShallowEqual<TSlotProps>(portalProps, prevProps);

    if (!propsChanged) {
      return;
    }

    updateSlotPortalProps(slotId, extensionId, portalId, portalProps);
  });
};
